export default [
  {
    path: "myself",
    // component: () => import("@/views/myself/views/MyselfRoot.vue"),
    component: () => import("@/views/community/SpaceRoot.vue" /* webpackChunkName: "SpaceRoot" */),
    redirect: { name: 'myDashboard' },
    meta: {
      breadcrumb: [],
    },
    children: [
      // MYSELF -> DASHBOARD
      {
        path: "",
        name: "myDashboard",
        component: () => import("@/views/myself/views/Dashboard.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> GLOBAL SETTINGS
      {
        path: "global-settings",
        name: "globalMemberSettings",
        component: () => import("@/views/myself/views/GlobalSettings.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> LOCAL SETTINGS
      {
        path: "local-settings",
        name: "localMemberSettings",
        component: () => import("@/views/myself/views/LocalSettings.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> CONTACT DATA
      {
        path: "contact-info",
        name: "contactInfo",
        component: () => import("@/views/myself/views/ContactInfo.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> ROLES
      {
        path: "roles",
        name: "myRoles",
        component: () => import("@/views/myself/views/MyRoles.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "roles/:id",
        name: "my-role-details",
        component: () => import("@/views/myself/components/MyRoleDetails.vue"),
        props: true,
        meta: {
          pageTitle: "backoffice.roles.role-detail",
        },
      },
      // MYSELF -> CALENDAR
      {
        path: "calendar",
        name: "calendar",
        component: () => import("@/views/apps/calendar/components/CalendarDetails.vue"),
        props: true,
      },
      // MYSELF -> CONNECTIONS
      {
        path: "connections",
        name: "myConnections",
        component: () => import("@/views/myself/views/MyConnections.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> MESSAGES
      {
        path: "messages",
        name: "email",
        component: () => import("@/views/myself/views/Email.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "messages/:messageId",
        name: "EmailDetails",
        component: () => import("@/views/myself/components/EmailDetails.vue"),
        props: true,
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> CHAT
      {
        path: "chat",
        name: "chats",
        component: () => import("@/views/myself/views/Chat.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> PASSPORT (badge, accreditation...)
      {
        path: "passport",
        name: "passport",
        component: () => import("@/views/myself/views/Passport.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> ORDERS
      {
        path: "orders",
        name: "orders",
        component: () => import("@/views/myself/views/Tickets.vue"),
        props: true,
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> TRANSACTIONS
      {
        path: "transactions",
        name: "my-payments",
        component: () => import("@/views/myself/views/MyPayments.vue"),
        props: true,
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> REFERRALS
      {
        path: "referrals",
        name: "myself-referrals",
        component: () => import("@/views/myself/views/Referrals.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> NOTIFICATIONS
      {
        path: "notifications",
        name: "myself-notifications",
        component: () => import("@/views/myself/views/Notifications.vue"),
        props: true,
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> SPACES
      {
        path: "spaces",
        name: "myself-subcommunities",
        component: () => import("@/views/myself/views/MySubcommunities.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // MYSELF -> LIKES
      {
        path: "likes",
        name: "myself-likes",
        component: () => import("@/views/myself/views/MyLikes.vue"),
        props: true,
        meta: {
          breadcrumb: [],
        },
      },
    ],
  },
];
