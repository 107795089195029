export default [
  {
    path: '/:communityId/sso-login',
    name: 'SSO Auth',
    component: () => import('@/auth/views/SSOAuth.vue'),
    props: true,
  },
  {
    path: "/:communityId/login",
    name: "auth-login",
    component: () => import("@/auth/views/Login.vue"),
    meta: {
      redirectIfLoggedIn: true,
      layout: "full",
    },
  },
  {
    path: "/:communityId/register",
    name: "auth-register",
    component: () => import("@/auth/views/Register.vue"),
    meta: {
      redirectIfLoggedIn: true,
      layout: "full",
    },
  },
  {
    path: "/:communityId/signup",
    name: "auth-signup",
    component: () => import("@/auth/views/Signup.vue"),
    meta: {
      redirectIfLoggedIn: true,
      layout: "full",
    },
  },
  {
    path: "/:communityId/forgot-password",
    name: "auth-forgot",
    component: () => import("@/auth/views/Forgot.vue"),
    meta: {
      redirectIfLoggedIn: false,
      layout: "full",
    },
  },
  {
    path: "/:communityId/reset-password",
    name: "auth-reset",
    component: () => import("@/auth/views/Reset.vue"),
    meta: {
      redirectIfLoggedIn: false,
      layout: "full",
    },
  },
  {
    path: "/:communityId/terms-and-conditions",
    name: "terms",
    component: () => import("@/views/legal/TermsConditions.vue"),
    meta: {
      redirectIfLoggedIn: false,
      layout: "full",
    },
  },
  {
    path: "/:communityId/legal-center/:type?",
    name: "legal-center",
    component: () => import("@/views/legal/LegalTexts.vue"),
    meta: {
      redirectIfLoggedIn: false,
      layout: "full",
    },
  },
  /*{
    path: '/login/google/callback',
    name: 'Google ballback',
    component: () => import('@/auth/views/googleAuth.vue'),
    props: true,
  },*/
  {
    path: '/linkedinCallback',
    name: 'Linkedin ballback',
    component: () => import('@/auth/views/linkedinAuth.vue'),
    props: true,
  }
];
