export default [
  {
    path: "backoffice",
    //name: "backoffice",
    component: () => import("@/backoffice/views/BackofficeRoot.vue"),
    //component: () => import("@/backoffice/views/Dashboard.vue"),
    meta: {
      breadcrumb: [],
    },
    children: [
      {
        path: "",
        name: "backoffice",
        component: () => import("@/backoffice/views/Dashboard.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "settings",
        name: "settings",
        component: () => import("@/backoffice/views/settings/Settings.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "contact-info",
        name: "bo-contact",
        component: () => import("@/backoffice/views/contact-info/ContactData.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "templates",
        name: "templates-bo",
        component: () => import("@/backoffice/views/Templates.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "owner",
        name: "owner",
        component: () => import("@/backoffice/views/Owner.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "auth",
        name: "auth",
        component: () => import("@/backoffice/views/Auth.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "analytics",
        name: "analytics",
        component: () => import("@/backoffice/views/analytics/Dashboard.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // {
      //   path: "analytics/history",
      //   name: "history",
      //   component: () => import("@/backoffice/views/analytics/History.vue"),
      //   meta: {
      //     breadcrumb: [],
      //   },
      // },
      {
        path: "data-manager",
        name: "data-manager",
        component: () => import("@/backoffice/views/data-manager/Dashboard.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "payments",
        name: "payments",
        component: () => import("@/backoffice/views/Payments.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "discounts",
        name: "discounts-bo",
        component: () => import("@/backoffice/views/Discounts.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "transactions",
        name: "transactions",
        component: () => import("@/backoffice/views/Transactions.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "invoices",
        name: "invoices",
        component: () => import("@/backoffice/views/Invoices.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "shipments",
        name: "shipments",
        component: () => import("@/backoffice/views/Shipments.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // {
      //   path: "referrals",
      //   name: "backoffice-referrals",
      //   component: () => import("@/backoffice/views/Referrals.vue"),
      //   meta: {
      //     breadcrumb: [],
      //   },
      // },
      // {
      //   path: "roles",
      //   name: "roles",
      //   props: true,
      //   component: () => import("@/backoffice/views/roles/Roles.vue"),
      //   meta: {
      //     breadcrumb: [],
      //   },
      // },
      {
        path: "members",
        name: "backoffice-members",
        component: () => import("@/backoffice/views/members/MembersList.vue"),
        props: true,
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "members/:username",
        name: "backoffice-members-details",
        component: () =>
          import("@/backoffice/views/members/details/MemberDetails.vue"),
        props: true,
        meta: {
          pageTitle: "members.page-title.member-details",
        },
      },
      {
        path: "staff",
        name: "staff",
        props: true,
        component: () => import("@/backoffice/views/members/StaffList.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "roles",
        name: "roles",
        props: true,
        component: () => import("@/backoffice/views/roles/CommunityRoles.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "roles/:id",
        name: "role-details",
        component: () =>
          import("@/backoffice/views/roles/details/RoleDetail.vue"),
        props: true,
        meta: {
          pageTitle: "backoffice.roles.role-detail",
        },
      },
      {
        path: "staff-roles",
        name: "staff-roles",
        props: true,
        component: () => import("@/backoffice/views/roles/BackofficeRoles.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "staff-roles/:id",
        name: "staff-role-details",
        component: () =>
          import("@/backoffice/views/roles/details/StaffRoleDetail.vue"),
        props: true,
        meta: {
          pageTitle: "backoffice.roles.role-detail",
        },
      },
      {
        path: "organizations",
        name: "backoffice-organizations",
        component: () => import("@/backoffice/views/organizations/OrganizationsList.vue"),
        props: true,
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "organizations/:slug",
        name: "backoffice-organization-details",
        component: () =>
          import("@/backoffice/views/organizations/OrganizationDetails.vue"),
        props: true,
        meta: {
          pageTitle: "organizations.page-title.organization-details",
        },
      },
      // {
      //   path: "developers-center",
      //   name: "developers-center",
      //   props: true,
      //   component: () => import("@/backoffice/views/DevCenter.vue"),
      //   meta: {
      //     breadcrumb: [],
      //   },
      // },
      // {
      //   path: "coordination-center",
      //   name: "coordination-center",
      //   props: true,
      //   component: () => import("@/backoffice/views/CoordinationCenter.vue"),
      //   meta: {
      //     breadcrumb: [],
      //   },
      // },
      {
        path: "nectios-subscription",
        name: "subscriptions",
        props: true,
        component: () => import("@/backoffice/views/ManageSubscriptions.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "classifiers",
        name: "classifiers",
        props: true,
        component: () =>
          import("@/backoffice/views/classifiers/Classifiers.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "classifiers/:id",
        name: "classifier-details",
        component: () =>
          import("@/backoffice/views/classifiers/details/ClassifierDetail.vue"),
        props: true,
        meta: {
          pageTitle: "backoffice.classifiers.classifier-detail",
        },
      },
      {
        path: "custom-fields",
        name: "custom-fields",
        props: true,
        component: () =>
          import("@/backoffice/views/custom-fields/CustomFields.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "custom-fields/:id",
        name: "custom-fields-details",
        component: () =>
          import(
            "@/backoffice/views/custom-fields/details/CustomFieldDetail.vue"
          ),
        props: true,
        meta: {
          pageTitle: "backoffice.classifiers.classifier-detail",
        },
      },
      // {
      //   path: "availability",
      //   name: "availability",
      //   props: true,
      //   component: () => import("@/backoffice/views/Availability.vue"),
      //   meta: {
      //     breadcrumb: [],
      //   },
      // },
      {
        path: "feedback",
        name: "feedback",
        props: true,
        component: () => import("@/backoffice/views/feedback/Feedback.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "feedback/:id",
        name: "feedback-details",
        props: true,
        component: () =>
          import("@/backoffice/views/feedback/details/FeedbackDetails.vue"),
        meta: {
          pageTitle: "backoffice.classifiers.classifier-detail",
        },
      },
      // App store
      {
        path: "apps",
        name: "appstore",
        props: true,
        component: () => import("@/backoffice/views/apps/Apps.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "apps/:id",
        name: "addon",
        props: true,
        component: () =>
          import("@/backoffice/views/apps/details/AppDetails.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      //Legal Center
      {
        path: "legal",
        name: "backoffice-legal",
        props: true,
        component: () => import("@/backoffice/views/Legal.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      // Onboarding form
      {
        path: "onboarding-form",
        name: "backoffice-onboarding-form",
        props: true,
        component: () => import("@/backoffice/views/onboarding/ManageForm.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      //Plans
      {
        path: "plans",
        name: "plans",
        props: true,
        component: () => import("@/backoffice/views/Subscriptions.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      //Types
      {
        path: "types",
        name: "types",
        props: true,
        component: () => import("@/backoffice/views/types/Types.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "statuses",
        name: "statuses",
        props: true,
        component: () => import("@/backoffice/views/statuses/Statuses.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "forms",
        name: "forms-bo",
        component: () => import("@/backoffice/views/forms/Forms.vue"),
        meta: {
          breadcrumb: [],
        },
      },
      {
        path: "forms/:id",
        name: "forms-bo-details",
        component: () =>
          import("@/backoffice/views/forms/details/FormDetail.vue"),
        props: true,
        meta: {
          pageTitle: "backoffice.communication.forms.page-title.forms-details",
          goBackAnchor: "forms-bo",
        },
      }
    ]
  }
];
